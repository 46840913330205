<template>
  <div id="entities-table" v-if="entities">
    <vs-table
      max-items="10"
      pagination
      :search="true"
      :data="entities"
      :no-data-text="lang.general.noDataText[languageSelected]"
    >
      <template slot="thead">
        <vs-th sort-key="name">{{
          lang.entities.name[languageSelected]
        }}</vs-th>
        <vs-th>{{
          lang.entities.edit.valuesAndSynonyms[languageSelected]
        }}</vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.name">{{ tr.name }}</vs-td>

          <vs-td :data="tr.values">
            <ul>
              <li v-for="(value, index) in tr.values" :key="index">
                <div class="vx-row mb-3 mt-3">
                  <vs-col>
                    <vs-chip class="mr-2 mt-3" color="primary">{{
                      value.value
                    }}</vs-chip>
                    <vs-chip
                      class="mr-2 mt-3 text-dark"
                      color="secundary"
                      v-for="(synonym, index) in sortSynonyms(value.synonyms)"
                      :key="index"
                      >{{ synonym }}</vs-chip
                    >
                  </vs-col>
                </div>
                <vs-divider v-if="index !== tr.values.length - 1" />
              </li>
            </ul>
          </vs-td>

          <vs-td>
            <div class="action-buttons">
              <vs-button
                class="with-space"
                type="flat"
                @click="edit(tr)"
                icon="create"
              ></vs-button>
              <vs-button
                type="flat"
                @click="remove(indextr)"
                icon-pack="feather"
                icon="icon-trash"
              ></vs-button>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'

import BotMakerService from '../../../services/botMaker.service'

export default {
  name: 'EntitiesTable',
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  props: {
    toggleEntityEdit: Function
  },
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'entities'])
  },
  methods: {
    ...mapMutations('botMaker', ['SPLICE_ENTITY']),
    edit(tr) {
      this.toggleEntityEdit(tr)
    },
    sortSynonyms(synonyms) {
      const copy = JSON.parse(JSON.stringify(synonyms))
      return copy.sort((a, b) => {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      })
    },
    remove: function(indextr) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.entities.remove.dialog.title[this.languageSelected],
        text: this.lang.entities.remove.dialog.text[this.languageSelected],
        accept: async () => {
          this.$vs.loading()
          try {
            await BotMakerService.removeEntity(this.entities[indextr]._id)
            this.SPLICE_ENTITY(indextr)
            this.$vs.notify({
              title: this.lang.entities.remove.success.title[
                this.languageSelected
              ],
              text: this.lang.entities.remove.success.text[
                this.languageSelected
              ],
              color: 'success',
              position: 'top-right'
            })
            this.$vs.loading.close()
          } catch (error) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: this.lang.entities.remove.error.title[
                this.languageSelected
              ],
              text: this.lang.entities.remove.error.text[this.languageSelected],
              color: 'danger',
              position: 'top-right'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.action-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
}
.with-space {
  margin-right: 5px;
}
#entities-table {
  .vs-table--header {
    justify-content: flex-end;
  }
}
</style>

<template>
  <section id="entities">
    <add-entity ref="addEntity" :version="version" />
    <edit-entity ref="editEntity" :entity="selected" />
    <vs-row>
      <vs-col class="sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/1">
        <div v-if="inBotSettings">
          <entities-table :toggleEntityEdit="toggleEntityEdit" />
        </div>
        <vx-card v-else class="overflow-hidden mb-2 entities-card">
          <div class="vx-row">
            <vs-col>
              <vs-button class="float-right" icon="add" @click="toggleAddEdit">
                {{ lang.botMaker.addEntity.title[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col>
              <entities-table :toggleEntityEdit="toggleEntityEdit" />
            </vs-col>
          </div>
          <br />
        </vx-card>
      </vs-col>
    </vs-row>
  </section>
</template>

<script>
import EntitiesTable from './EntitiesTable.vue'
import AddEntity from './AddEntity.vue'
import EditEntity from './EditEntity.vue'

import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Entities',
  props: {
    inBotSettings: Boolean
  },
  components: {
    EntitiesTable,
    AddEntity,
    EditEntity
  },
  data() {
    return {
      keyComponents: new Date().getTime(),
      version: null,
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'entities'])
  },
  methods: {
    ...mapMutations('botMaker', ['START_STORE', 'SET_BOT_ID']),
    ...mapActions('botMaker', [
      'GET_API_CALLS',
      'GET_ENTITIES',
      'GET_BOT',
      'GET_ACTIVE_VERSION',
      'GET_BOT_VERSIONS'
    ]),
    toggleAddEdit() {
      this.$nextTick(() => {
        this.$refs.addEntity.openPopup()
      })
    },
    toggleEntityEdit(tr) {
      this.selected = tr
      this.$nextTick(() => {
        this.$refs.editEntity.openPopup()
      })
    }
  },
  async mounted() {
    if (!this.inBotSettings) {
      const botId = this.$route.query.bot
      const serviceId = this.$route.query.service
      const version = this.$route.query.version

      this.version = version

      this.$vs.loading()
      if (!this.bot || this.bot.id !== botId) {
        this.START_STORE()
        this.SET_BOT_ID(botId)
        try {
          await this.GET_BOT(botId)

          if (this.bot.legacy) {
            this.$notify.warning(
              this.lang.botMaker.legacyBot[this.languageSelected]
            )
            this.$router.push('/bot-maker/bots')
          } else {
            await this.GET_ACTIVE_VERSION([serviceId, this.$route.query.version])
            await this.GET_BOT_VERSIONS([serviceId, this.$route.query.version])
          }
          this.finishedLoading = true
        } catch (error) {
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
          this.$log.error(error)
          return await Promise.reject(error)
        }
      }
      await this.GET_ENTITIES(this.bot.activeVersion)
      this.$vs.loading.close()
    }
  },
  watch: {
    'bot.activeVersion'(newValue) {
      this.GET_ENTITIES(newValue)
      /** Reload add entity popup as the version prop was not changing */
      this.version = newValue
    }
  }
}
</script>

<style lang="scss">
#entities {
  .entities-card {
    background: #fafafa;
  }
}
</style>
